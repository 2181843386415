<template>
<div class="content">
        <div class="bg"></div>
        <div class="px-3 d-flex justify-content-center box-input">
            <div class="align-self-center text-center">
                <img src="@/assets/images/close.png" width="119" height="119" class="mb-5">
                <div class="fc-3 tx-29 lh-30p mb-2">ข้อมูลไม่ถูกต้อง</div>
                <div class="fc-3 tx-29 lh-30p">กรุณาติดต่อผู้ดูแลระบบ</div>
            </div>
        </div>
        
        <div class="bottom px-3 my-3">
            <div class="text-center tx-14 lh-21p fc-1 mb-2">
                <div>Power by: Yellow Idea Co., Ltd</div>
                <div>ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
            </div>

            <div class="text-center mb-3">
                <img src="@/assets/images/logo.png" width="111">
            </div>

        </div>
    </div>
</template>

<script>
export default {
    
}
</script>